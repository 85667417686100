import React from 'react';
import { Helmet } from 'react-helmet';
import Header from './components/header';
import Hero from './components/hero';
import HomePageBooks from './components/HomepageBooks';
import HomePageProduct from './components/HomepageProduct';
import HomePageComic from './components/HomepageComic';
import Footer from './components/footer';
import './styles.css';
import SBTwitter from './images/story_build_twitter.png';
import SBSocial from './images/story_build_social.png';

function App() {
  return (
    <div className="App">
      <Helmet>
        <title>StoryBuild, a division of Begin the work, LLC</title>
        <meta name="description" content="Products for the digitally distracted." />
        <meta property="og:title" content="StoryBuild Publishing" />
        <meta property="og:description" content="Browse our published books and shop for Product Journals." />
        <meta property="og:image" content={SBSocial} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:url" content="https://story.build" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="StoryBuild Publishing" />
        <meta name="twitter:description" content="Products for the digitally distracted. Browse our published books and shop for Product Journals." />
        <meta name="twitter:image" content={SBTwitter} />
        <meta name="twitter:image:width" content="1200" />
        <meta name="twitter:image:height" content="628" />
        <meta name="twitter:url" content="https://story.build" />
      </Helmet>
      <Header />
      <Hero />
      <HomePageBooks />
      <HomePageProduct />
      <HomePageComic />
      <Footer />
    </div>
  );
}

export default App;
