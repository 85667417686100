import React from 'react';
import BookContainer from './BookContainer';
import '../styles.css'; // Assuming styles.css includes the necessary styles
import blueBanner from '../images/Blue-Banner.png';
import Begin from '../images/begin.png';
import Begun from '../images/begun.png';
import BeginAgain from '../images/begin-again.png';

const books = [
  {
    title: 'Begin',
    author: 'Samuel DuRegger',
    description: 'Book 1 of 3 in the Begin the work trilogy. "Begin" takes us to the beginning, starting with context and reason, the what and the why.',
    image: Begin,
    link: 'https://read.story.build', // The URL to which the image should link
    bannerImage: blueBanner,
    bannerText: 'Coming Fall 2024'
  },
  {
    title: 'Begun',
    author: 'Samuel DuRegger',
    description: 'Book 2 of 3. We want you to finish what you have "Begun" by slaying dragons and organizing choas. It is about progress over process.',
    image: Begun,
    link: 'https://read.story.build', // The URL to which the image should link
    bannerImage: blueBanner,
    bannerText: 'Coming in 2025'
    
  },
  {
    title: 'Begin Again',
    author: 'Samuel DuRegger',
    description: 'Book 3 of 3. We help you begin, and finish what you have begun, so you can begin again. Success or failure the proper choice is to begin again.',
    image: BeginAgain,
    link: 'https://read.story.build', // The URL to which the image should link
    bannerImage: blueBanner,
    bannerText: 'Coming in 2025'
  },
  // Add more books as needed
];

const HomepageBooks = () => {
  return (
    <div className="homepage-books">
      {books.map((book, index) => (
        <BookContainer key={index} book={book} bannerImage={book.bannerImage} bannerText={book.bannerText} />
      ))}
    </div>
  );
};

export default HomepageBooks;
