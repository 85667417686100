import React, { useState, useEffect, useMemo } from 'react';
import Header from './header';
import Footer from './footer';
import '../styles.css';
import './shop.css';
import image1_1 from '../images/Solar_Yellow.png';
import image1_2 from '../images/Dot-Grid+Larger_Screens_Inside.png';
import image2_1 from '../images/Cosmic_Orange.png';
import image2_2 from '../images/Dot-Grid+Larger_Screens_Inside.png';
import image3_1 from '../images/Lunar_Blue.png';
import image3_2 from '../images/Dot-Grid+Larger_Screens_Inside.png';
import image4_1 from '../images/Patriot_Blue.png';
import image4_2 from '../images/Dot-Grid+Larger_Screens_Inside.png';
import image5_1 from '../images/Re-Entry_Red.png';
import image5_2 from '../images/Dot-Grid+Larger_Screens_Inside.png';
import image6_1 from '../images/DG-Patriot_Blue.png';
import image6_2 from '../images/DG-Inside.png';
import image7_1 from '../images/DG-Midnight_Black.png';
import image7_2 from '../images/DG-Inside.png';
import image8_1 from '../images/DG+PS-Midnight_Black.png';
import image8_2 from '../images/DG+PS-Midnight_Black_Inside.png';
import image9_1 from '../images/Cowboy_Sam.png';
import image9_2 from '../images/Cowboy_Sam_Inside.png';
import image10_1 from '../images/Mermaid_Marissa.png';
import image10_2 from '../images/Mermaid_Marissa_Prompt.png';
import leftArrow from '../images/left-arrow.svg';
import rightArrow from '../images/right-arrow.svg';
import redBanner from '../images/Red-Banner.png';
import image11_1 from '../images/Field-Note_Wallet_Camel.png';
import image11_2 from '../images/Field-Note_Wallet-Inside_Camel.png';
import image12_1 from '../images/Field-Note_Wallet_Whiskey.png';
import image12_2 from '../images/Field-Note_Wallet-Inside_Whiskey.png';
import image13_1 from '../images/BLK-Cover.PNG';
import image13_2 from '../images/BLK-Back.PNG';
import image13_3 from '../images/BLK-Inside-1.PNG';
import image13_4 from '../images/BLK-Inside-2.PNG';
import image13_5 from '../images/BLK-Inside-3.PNG';
import image14_1 from '../images/WH-Cover.PNG';
import image14_2 from '../images/WH-Back.PNG';
import image14_3 from '../images/WH-Inside-1.PNG';
import image14_4 from '../images/WH-Inside-2.PNG';
import image14_5 from '../images/WH-Inside-3.PNG';

const products = [
 
 {
   id: 14,
   name: 'Leather Journal Cover - Whiskey',
   category: 'Leather Goods',
   buyButtonId: 'buy_btn_1PwTOyHT8sP8RVs6WCjl0TlL',
   description: 'Closed dimensions: 8.75” x 5.75”. Hand-made leather cover for Product Journal and Dot Grid Journals with a discrete StoryBuild logo burnt into the back of the cover. This leather cover is meant to protect your journals as you collect your ideas, concepts, and sketches.',
   images: [image14_1, image14_2, image14_3, image14_4, image14_5],
   price: '$45.00'
 },
 
 {
    id: 13,
    name: 'Leather Journal Cover - Black',
    category: 'Leather Goods',
    buyButtonId: 'buy_btn_1PwTPLHT8sP8RVs6XgWyEG0o',
    description: 'Closed dimensions: 8.75” x 5.75”. Hand-made black leather cover for Product Journal and Dot Grid Journals with a discrete StoryBuild logo burnt into the back of the cover. This leather cover is meant to protect your journals as you collect your ideas, concepts, and sketches.',
    images: [image13_1, image13_2, image13_3, image13_4, image13_5],
    price: '$45.00'
  },
 
 
 
 
 
 
 {
   id: 11,
   name: 'Field Note Wallet - Camel',
   category: 'Leather Goods',
   buyButtonId: 'buy_btn_1PuF7OHT8sP8RVs6QMb6OHcu',
   description: 'Closed dimensions: 4.50” x 5.75”. Two folded cash slots, four card pockets. Handcrafted from beautiful full grain leather, in a lighter Camel color.',
   images: [image11_1, image11_2],
   price: '$40.00'
 },
 
{
    id: 12,
    name: 'Field Note Wallet - Whiskey',
    category: 'Leather Goods',
    buyButtonId: 'buy_btn_1PuF5vHT8sP8RVs6znFjEXCk',
    description: 'Closed dimensions: 4.50” x 5.75”. Two folded cash slots, four card pockets. Handcrafted from beautiful full grain leather, in a darker Whiskey color.',
    images: [image12_1, image12_2],
    price: '$40.00'
  },
 
  {
    id: 8,
    name: 'Dot Grid + Screens Product Journal - Midnight Black',
    category: 'Product Journal',
    buyButtonId: 'buy_btn_1PcxTeHT8sP8RVs6MHvtue3N',
    description: 'Dot Grid on one side, 9 mobile screens on the other, perfect bound, 62 pages, 270 screens.',
    images: [image8_1, image8_2],
    specialDeal: '20% OFF',
    price: '$9.60 | 2-pack'
  },
  {
    id: 1,
    name: 'Solar Yellow Product Journal',
    category: 'Product Journal',
    buyButtonId: 'buy_btn_1PX1NWHT8sP8RVs6byH0MFBn',
    description: '2-pack of StoryBuild Product Journals. Dot Grid is on one side, and three large Screens are on the other. 62 pages, 90 large screens.',
    images: [image1_1, image1_2],
    price: '$12.00 | 2-pack'
  },
  {
    id: 2,
    name: 'Cosmic Orange Product Journal',
    category: 'Product Journal',
    buyButtonId: 'buy_btn_1PX1UjHT8sP8RVs6HKhuRTXI',
    description: '2-pack of StoryBuild Product Journals. Dot Grid is on one side, and three large Screens are on the other. 62 pages, 90 large screens.',
    images: [image2_1, image2_2],
    price: '$12.00 | 2-pack'
  },
  {
    id: 3,
    name: 'Lunar Blue Product Journal',
    category: 'Product Journal',
    buyButtonId: 'buy_btn_1PX1TkHT8sP8RVs6hFSF4okG',
    description: '2-pack of StoryBuild Product Journals. Dot Grid is on one side, and three large Screens are on the other. 62 pages, 90 large screens.',
    images: [image3_1, image3_2],
    price: '$12.00 | 2-pack'
  },
  {
    id: 4,
    name: 'Patriot Blue Product Journal',
    category: 'Product Journal',
    buyButtonId: 'buy_btn_1PX1OeHT8sP8RVs6ECZxDuym',
    description: '2-pack of StoryBuild Product Journals. Dot Grid is on one side, and three large Screens are on the other. 62 pages, 90 large screens.',
    images: [image4_1, image4_2],
    price: '$12.00 | 2-pack'
  },
  {
    id: 5,
    name: 'Re-Entry Red Product Journal',
    category: 'Product Journal',
    buyButtonId: 'buy_btn_1PX1SdHT8sP8RVs6i6387k9W',
    description: '2-pack of StoryBuild Product Journals. Dot Grid is on one side, and three large Screens are on the other. 62 pages, 90 large screens.',
    images: [image5_1, image5_2],
    price: '$12.00 | 2-pack'
  },
  {
    id: 6,
    name: 'Dot Grid Journal - Patriot Blue',
    category: 'Dot Grid Journal',
    buyButtonId: 'buy_btn_1PX0qIHT8sP8RVs65iwzqVzf',
    description: 'Dot Grid Journal, perfect bound, 62 pages.',
    images: [image6_1, image6_2],
    price: '$12.00 | 2-pack'
  },
  {
    id: 7,
    name: 'Dot Grid Journal - Midnight Black',
    category: 'Dot Grid Journal',
    buyButtonId: 'buy_btn_1PX0nrHT8sP8RVs600nCk31C',
    description: ' | Dot Grid Journal, perfect bound, 62 pages.',
    images: [image7_1, image7_2],
    price: '$12.00 | 2-pack'
  },

  {
    id: 9,
    name: 'Cowboy Sam & His Dog Gus',
    category: 'Draw Your Own Adventure',
    buyButtonId: 'buy_btn_1PcxhtHT8sP8RVs6FMFocUz0',
    description: 'Draw Your Own Adenture with story prompts and 5 different comic layouts. Includes a section on "how-to-draw" the main characters.',
    images: [image9_1, image9_2],
    price: '$8.00 | per comic book'
  },
  {
    id: 10,
    name: 'Mermaid Marissa & Gerald the Shark',
    category: 'Draw Your Own Adventure',
    buyButtonId: 'buy_btn_1PcxitHT8sP8RVs6W2NFd6Ap',
    description: 'Draw Your Own Adenture with story prompts and 5 different comic layouts. Includes a section on "how-to-draw" the main characters.',
    images: [image10_1, image10_2],
    price: '$8.00 | per comic book'
  },
];

const Shop = () => {
  const [category, setCategory] = useState('All');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentImages, setCurrentImages] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const categories = useMemo(() => ['All', 'Leather Goods', 'FieldProduct Journal', 'Dot Grid Journal', 'Draw Your Own Adventure'], []);

  const handleCategoryClick = (category) => {
    setCategory(category);
    window.location.hash = category; // Update the URL hash
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://js.stripe.com/v3/buy-button.js";
    script.async = true;
    document.body.appendChild(script);
    
    script.onload = () => {
      console.log('Stripe script loaded');
    };

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    const hash = window.location.hash.replace('#', '').replace(/%20/g, ' ');
    if (hash && categories.includes(hash)) {
      setCategory(hash);
    }
  }, [categories]);

  const filteredProducts = category === 'All'
    ? products
    : products.filter((product) => product.category === category);

  const openModal = (images) => {
    setCurrentImages(images);
    setCurrentImageIndex(0);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const nextImage = () => {
    setCurrentImageIndex((currentImageIndex + 1) % currentImages.length);
  };

  const prevImage = () => {
    setCurrentImageIndex((currentImageIndex - 1 + currentImages.length) % currentImages.length);
  };

  return (
    <div className="shop-page">
      <Header />
      <div className="content-container">
        <div className="categories">
          <ul>
            {categories.map((cat) => (
              <li
                key={cat}
                onClick={() => handleCategoryClick(cat)}
                className={category === cat ? 'active' : ''}
              >
                {cat}
              </li>
            ))}
          </ul>
        </div>
        <main className="content">
          <div className="products-grid">
            {filteredProducts.map((product) => (
              <div key={product.id} className="product-item">
                {product.specialDeal && (
                  <div className="special-deal-banner">
                    <img src={redBanner} alt="Special Deal" className="special-deal-banner-image" />
                    <span className="special-deal-banner-text">{product.specialDeal}</span>
                  </div>
                )}
                <div className="product-images" onClick={() => openModal(product.images)}>
                  <img src={product.images[0]} alt={`${product.name} Cover`} />
                </div>
                <h3>{product.name}</h3>
                <p>{product.description}</p>
                <p className="product-price">{product.price}</p>
                <stripe-buy-button
                  buy-button-id={product.buyButtonId}
                  publishable-key="pk_live_3JuBiNhI0Rjck67EReu5hNvn"
                ></stripe-buy-button>
              </div>
            ))}
          </div>
        </main>
      </div>
      <Footer />

      {modalIsOpen && (
        <div className="modal">
          <button className="close-btn" onClick={closeModal}>X</button>
          <button className="prev-btn" onClick={prevImage}>
            <img src={leftArrow} alt="Previous" />
          </button>
          <img src={currentImages[currentImageIndex]} alt="" />
          <button className="next-btn" onClick={nextImage}>
            <img src={rightArrow} alt="Next" />
          </button>
        </div>
      )}
    </div>
  );
};

export default Shop;
