import React, { useEffect, useState } from 'react';
import '../styles.css';
import './hero.css';
import heroImage1 from '../images/StoryBuild_Logo_Tag.png';

const images = [
  heroImage1,
];

const Hero = () => {
  const [heroImage, setHeroImage] = useState('');

  useEffect(() => {
    const randomImage = images[Math.floor(Math.random() * images.length)];
    setHeroImage(randomImage);
  }, []);

  return (
    <section className="hero">
      <img src={heroImage} alt="Hero" className="hero-image" />
      <div className="hero-content">
      </div>
    </section>
  );
};

export default Hero;
