import React from 'react';
import logo from '../images/StoryBuild_Badge.png';
import '../styles.css';


const Footer = () => {
  return (
    <footer className="footer">
      <div className="logo">
      <a href="https://story.build"><img src={logo} alt="Logo" /></a>
      </div>
      <p>Products for the digitally distracted.</p>
      <p>&copy; 2024 StoryBuild Publishing, a division of Begin the work, LLC.</p>
    </footer>
  );
}

export default Footer;
