import React, { useState } from 'react';
import '../styles.css'; // Assuming styles.css includes the necessary styles
import bookIconClosed from '../images/book-icon-closed.png';
import bookIconOpen from '../images/book-icon-open.png';

const ProductContainer = ({ book, bannerImage, bannerText }) => {
  const [showDescription, setShowDescription] = useState(false);

  const handleToggleDescription = () => {
    setShowDescription(!showDescription);
  };

  return (
    <div className="book-wrapper">
      <div
        className={`book-container ${showDescription ? 'clicked' : ''}`}
        onMouseEnter={(e) => e.currentTarget.classList.add('hovered')}
        onMouseLeave={(e) => e.currentTarget.classList.remove('hovered')}
      >
        {bannerImage && (
          <div className="book-banner">
            <img src={bannerImage} alt="Banner" className="banner-image" />
            <span className="banner-text">{bannerText}</span>
          </div>
        )}
        {showDescription ? (
          <div className="book-description">
            <p>{book.description}</p>
            <div className="book-icon-container">
              <img src={bookIconClosed} alt="Close book" className="book-icon" onClick={handleToggleDescription} />
            </div>
          </div>
        ) : (
          <div className="book-content">
            <a href={book.link} rel="noopener noreferrer">
              <img src={book.image} alt={book.title} className="book-image" />
            </a>
            <div className="book-icon-container">
              <img src={bookIconOpen} alt="Open book" className="book-icon" onClick={handleToggleDescription} />
            </div>
          </div>
        )}
      </div>
      <div className="book-info">
        <h3>{book.title}</h3>
        <p>by {book.author}</p>
      </div>
    </div>
  );
};

export default ProductContainer;
