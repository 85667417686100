import React from 'react';
import ProductContainer from './ProductContainer';
import '../styles.css'; // Assuming styles.css includes the necessary styles

import redBanner from '../images/Red-Banner.png';
import SolarYellow from '../images/DG+LS_Solar_Yellow_Product.png';
import DGPatriot from '../images/DG-Patriot_Blue_Product.png';
import DGPSMidnight from '../images/DG+PS-Midnight_Black_Product.png';

const books = [
  {
    title: 'Dot Grid + Screens',
    author: 'StoryBuild Publishing',
    description: '2-pack or 10-pack of Product Journals with Dot Grid on one side and nine vertical screens on the other. Innovate inside the box.',
    image: DGPSMidnight,
    link: './shop/#Product%20Journal', // The URL to which the image should link
    bannerImage: redBanner,
    bannerText: '20% Off'
    
  },
  
  {
    title: 'Dot Grid + Large Screens',
    author: 'StoryBuild Publishing',
    description: '2-pack of Product Journals available in five different colors. With Dot Grid on one side, and three large screens on the other. Draw the idea not the rectangle.',
    image: SolarYellow,
    link: './shop/#Product%20Journal', // The URL to which the image should link
  },
  
  {
    title: 'Dot Grid Journal',
    author: 'StoryBuild Publishing',
    description: '2-pack Dot Grid journals. 62 pages to create within. Choose from Midnight Black or Patriot Blue covers. Habituate ideation. ',
    image: DGPatriot,
    link: './shop/#Dot%20Grid%20Journal', // The URL to which the image should link
    
   
  },
  // Add more books as needed
];

const HomepageProduct = () => {
  return (
    <div className="homepage-books">
      {books.map((book, index) => (
        <ProductContainer key={index} book={book} bannerImage={book.bannerImage} bannerText={book.bannerText} />
      ))}
    </div>
  );
};

export default HomepageProduct;
