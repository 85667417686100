import React from 'react';
import ProductContainer from './ProductContainer';
import '../styles.css'; // Assuming styles.css includes the necessary styles
import blueBanner from '../images/Blue-Banner.png';
import CowboySam from '../images/Cowboy_Sam.png';
import MermaidMarissa from '../images/Mermaid_Marissa.png';

const books = [
  {
    title: 'Cowboy Sam and His Dog Gus',
    author: 'Samuel DuRegger',
    description: 'Draw Your Own Adenture with story prompts and comic layouts. Including a section on "how-to-draw" Cowboy Sam and his dog Gus.',
    image: CowboySam,
    link: './shop/#Draw%20Your%20Own%20Adventure', // The URL to which the image should link
    bannerImage: blueBanner,
    bannerText: 'For Kids 5-12'
  },
  {
    title: 'Mermaid Marissa and Gerald the Shark',
    author: 'Samuel DuRegger',
    description: 'Draw Your Own Adenture with story prompts and comic layouts. Including a section on "how-to-draw" Mermaid Marissa and Gerald the Shark.',
    image: MermaidMarissa,
    link: './shop/#Draw%20Your%20Own%20Adventure', // The URL to which the image should link
    bannerImage: blueBanner,
    bannerText: 'For Kids 5-12'
    
  },

  // Add more books as needed
];

const HomepageComics = () => {
  return (
    <div className="homepage-books">
      {books.map((book, index) => (
        <ProductContainer key={index} book={book} bannerImage={book.bannerImage} bannerText={book.bannerText} />
      ))}
    </div>
  );
};

export default HomepageComics;
